@import url(https://fonts.googleapis.com/css?family=Roboto);


.saleStats_GridRow__u3miT {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
}
.saleStats_GridColumn__3920T {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}
.saleStats_LeftSideGridContainer__2Ai6a {
  flex: 0 0 50px;
}

.saleStats_BodyGrid__JVi-f {
  width: 100%;
  border: 1px solid green;
}

.saleStats_evenRow__2DxN8,
.saleStats_oddRow__1tiTW {
  border-bottom: 1px solid #e0e0e0;
}

.saleStats_cell__3vggR {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.5em 1em;
}
.saleStats_cell__3vggR {
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}

.saleStats_uniformSizeCell__2hpkf {
  padding: 0.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.saleStats_tableRow__3Hsj3 {
  border-bottom: 1px solid #eee;
}
.saleStats_tableColumn__36DFU {
  padding: 5px 15px 5px 0;
}

.saleStats_Tab__1XkbB {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 4px 6px;
  outline: none;
  background: #eee;
  margin: 4px;
  cursor: pointer;
}

.saleStats_ActiveTab__1wqQn {
  background-color: #4db6ac;
  border: 1px solid #3ca59b;
  color: rgba(255, 255, 255, 0.8);
  cursor: default;
}

.saleStats_table__34ysI {
  border: 1px solid #ddd;
  position: relative;
  border-bottom: unset;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  height: 100%;
}

