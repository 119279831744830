.GridRow {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
}
.GridColumn {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}
.LeftSideGridContainer {
  flex: 0 0 50px;
}

.BodyGrid {
  width: 100%;
  border: 1px solid green;
}

.evenRow,
.oddRow {
  border-bottom: 1px solid #e0e0e0;
}

.cell {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.5em 1em;
}
.cell {
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}

.uniformSizeCell {
  padding: 0.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.tableRow {
  border-bottom: 1px solid #eee;
}
.tableColumn {
  padding: 5px 15px 5px 0;
}

.Tab {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 4px 6px;
  outline: none;
  background: #eee;
  margin: 4px;
  cursor: pointer;
}

.ActiveTab {
  background-color: #4db6ac;
  border: 1px solid #3ca59b;
  color: rgba(255, 255, 255, 0.8);
  cursor: default;
}

.table {
  border: 1px solid #ddd;
  position: relative;
  border-bottom: unset;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  height: 100%;
}
